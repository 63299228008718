// base dependency library, it should be only shared by `admin.js` and `application.js`.
import 'jquery'
import 'bootstrap/dist/js/bootstrap'

import RailsUjs from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

import 'bootstrap-select/dist/js/bootstrap-select.min'
import 'select2'
// import 'clipboard'

window.jQuery = $;
window.$ = $;

const images = require.context('images', true)
const imagePath = (name) => images(name, true)

RailsUjs.start()
Turbolinks.start()
ActiveStorage.start()

// new ClipboardJS('.clipboard-link');