$(document).on('turbolinks:load', function () {
  var component = $('.enquiries-new-page');
  if (component.length > 0) {
    $('select').select2({
      theme: 'bootstrap4',
      width: '100%' 
    });
    $('#enquiry_country').on('change', function () {
      return $('#enquiry_country_code').val(this.value.split('-').pop());
    });

  if ($('#enquiry_technical_specification').length > 0) {
    var content = 'You can include: <br>- Size/Dimension<br>- Color/Pattern<br>- Grade/Quality Standard<br>- Material<br>- Application<br>- Packaging/Packing<br>- Any other technical specification';
    return $('#enquiry_technical_specification').popover({
      trigger: 'focus',
      content: content,
      placement: 'top',
      html: true
    });
  }
  }
});
